export const PROPERTIES_REQUEST = "PROPERTIES_REQUEST";
export const PROPERTIES_SUCCESS = "PROPERTIES_SUCCESS";
export const PROPERTIES_FAIL = "PROPERTIES_FAIL";

export const PROPERTIES_FOR_RENT_REQUEST = "PROPERTIES_FOR_RENT_REQUEST";
export const PROPERTIES_FOR_RENT_SUCCESS = "PROPERTIES_FOR_RENT_SUCCESS";
export const PROPERTIES_FOR_RENT_FAIL = "PROPERTIES_FOR_RENT_FAIL";

export const PROPERTIES_FOR_SALE_REQUEST = "PROPERTIES_FOR_SALE_REQUEST";
export const PROPERTIES_FOR_SALE_SUCCESS = "PROPERTIES_FOR_SALE_SUCCESS";
export const PROPERTIES_FOR_SALE_FAIL = "PROPERTIES_FOR_SALE_FAIL";


export const PROPERTY_DETAILS_REQUEST = "PROPERTY_DETAILS_REQUEST";
export const PROPERTY_DETAILS_SUCCESS = "PROPERTY_DETAILS_SUCCESS";
export const PROPERTY_DETAILS_FAIL = "PROPERTY_DETAILS_SUCCESS";

export const CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY";
export const CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY";
export const CREATE_PROPERTY_FAIL = "CREATE_PROPERTY";

export const SPECIAL_PROPERTY = "SPECIAL_PROPERTY";

export const SEARCH_ALL_DATA_REQUEST = "SEARCH_ALL_DATA_REQUEST ";
export const SEARCH_ALL_DATA_SUCCESS = "SEARCH_ALL_DATA_SUCCESS";
export const SEARCH_ALL_DATA_FAIL = "SEARCH_ALL_DATA_FAIL";

export const FILTER_VACANT_PLOT_REQUEST = "FILTER_VACANT_PLOT_REQUEST";
export const FILTER_VACANT_PLOT_SUCCESS = "FILTER_VACANT_PLOT_SUCCESS";
export const FILTER_VACANT_PLOT_FAIL = "FILTER_VACANT_PLOT_FAIL";

export const FILTER_VACANT_PLOT_SALE_REQUEST =
  "FILTER_VACANT_PLOT_SALE_REQUEST";
export const FILTER_VACANT_PLOT_SALE_SUCCESS =
  "FILTER_VACANT_PLOT_SALE_SUCCESS";
export const FILTER_VACANT_PLOT_SALE_FAIL = "FILTER_VACANT_PLOT_SALE_FAIL";

export const FILTER_WAREHOUSE_REQUEST = "FILTER_WAREHOUSE_REQUEST";
export const FILTER_WAREHOUSE_SUCCESS = "FILTER_WAREHOUSE_SUCCESS";
export const FILTER_WAREHOUSE_FAIL = "FILTER_WAREHOUSE_FAIL";

export const FILTER_WAREHOUSE_SALE_REQUEST = "FILTER_WAREHOUSE_SALE_REQUEST";
export const FILTER_WAREHOUSE_SALE_SUCCESS = "FILTER_WAREHOUSE_SALE_SUCCESS";
export const FILTER_WAREHOUSE_SALE_FAIL = "FILTER_WAREHOUSE_SALE_FAIL";

export const FILTER_COMMERCIAL_REQUEST = "FILTER_COMMERCIAL_REQUEST";
export const FILTER_COMMERCIAL_SUCCESS = "FILTER_COMMERCIAL_SUCCESS";
export const FILTER_COMMERCIAL_FAIL = "FILTER_COMMERCIAL_FAIL";

export const FILTER_COMMERCIAL_SALE_REQUEST = "FILTER_COMMERCIAL_SALE_REQUEST";
export const FILTER_COMMERCIAL_SALE_SUCCESS = "FILTER_COMMERCIAL_SALE_SUCCESS";
export const FILTER_COMMERCIAL_SALE_FAIL = "FILTER_COMMERCIAL_SALE_FAIL";

export const FILTER_APARTMENTS_REQUEST = "FILTER_APARTMENTS_REQUEST";
export const FILTER_APARTMENTS_SUCCESS = "FILTER_APARTMENTS_SUCCESS";
export const FILTER_APARTMENTS_FAIL = "FILTER_APARTMENTS_FAIL";

export const FILTER_APARTMENTS_SALE_REQUEST = "FILTER_APARTMENTS_SALE_REQUEST";
export const FILTER_APARTMENTS_SALE_SUCCESS = "FILTER_APARTMENTS_SALE_SUCCESS";
export const FILTER_APARTMENTS_SALE_FAIL = "FILTER_APARTMENTS_SALE_FAIL";

export const FILTER_RESIDENTIAL_REQUEST = "FILTER_RESIDENTIAL_REQUEST";
export const FILTER_RESIDENTIAL_SUCCESS = "FILTER_RESIDENTIAL_SUCCESS";
export const FILTER_RESIDENTIAL_FAIL = "FILTER_RESIDENTIAL_FAIL";

export const FILTER_RESIDENTIAL_SALE_REQUEST =
  "FILTER_RESIDENTIAL_SALE_REQUEST";
export const FILTER_RESIDENTIAL_SALE_SUCCESS =
  "FILTER_RESIDENTIAL_SALE_SUCCESS";
export const FILTER_RESIDENTIAL_SALE_FAIL = "FILTER_RESIDENTIAL_SALE_FAIL";

export const FILTER_OFFICE_REQUEST = "FILTER_OFFICE_REQUEST";
export const FILTER_OFFICE_SUCCESS = "FILTER_OFFICE_SUCCESS";
export const FILTER_OFFICE_FAIL = "FILTER_OFFICE_FAIL";

export const FILTER_OFFICE_SALE_REQUEST = "FILTER_OFFICE_SALE_REQUEST";
export const FILTER_OFFICE_SALE_SUCCESS = "FILTER_OFFICE_SALE_SUCCESS";
export const FILTER_OFFICE_SALE_FAIL = "FILTER_OFFICE_SALE_FAIL";

export const CREATE_SPECIAL_PROPERTY_REQUEST =
  " CREATE_SPECIAL_PROPERTY_REQUEST";
export const CREATE_SPECIAL_PROPERTY_SUCCESS =
  "CREATE_SPECIAL_PROPERTY_SUCCESS";
export const CREATE_SPECIAL_PROPERTY_FAIL = "CREATE_SPECIAL_PROPERTY_FAIL";

export const USER_LOGIN = "USER_LOGIN";

export const FILTER_DATA_REQUEST = "FILTER_ALL_REQUEST";
export const FILTER_DATA_SUCCESS = "FILTER_DATA_SUCCESS";
export const FILTER_DATA_FAIL = "FILTER_DATA_FAIL";

export const RENT_CATEGORY = "RENT_CATEGORY";

export const SELL_CATEGORY = "SELL_CATEGORY";

export const SEARCH_CATEGORY = "SEARCH_CATEGORY";

export const FOR_RENT = "FOR_RENT";

export const FOR_SALE = "FOR_SALE";

export const UPDATE_PROFILE_REQUEST="UPDATE_PROFILE_REQUEST"
export const UPDATE_PROFILE_SUCCESS="UPDATE_PROFILE_SUCCESS"
export const UPDATE_PROFILE_FAIL="UPDATE_PRFILE_FAIL"

export const DEAL_REQUEST = "DEAL_REQUEST"
export const DEAL_SUCCESS = "DEAL_SUCCESS"
export const DEAL_FAIL = "DEAL_FAIL"

export const CREATE_DEAL_REQUEST = "CREATE_DEAL_REQUEST"
export const CREATE_DEAL_SUCCESS = "CREATE_DEAL_SUCCESS"
export const CREATE_DEAL_FAIL = "CREATE_DEAL_FAIL"

export const DEAL_DETAIL_REQUEST = "DEAL_DETAIL__REQUEST"
export const DEAL_DETAIL_SUCCESS = "DEAL_DETAIL__SUCCESS"
export const DEAL_DETAIL_FAIL = "DEAL_DETAIL__FAIL"

export const MY_DEAL_REQUEST = "MY_DEAL_REQUEST"
export const MY_DEAL_SUCCESS = "MY_DEAL_SUCCESS"
export const MY_DEAL_FAIL = "MY_DEAL_FAIL"

export const CREATE_LIKE_REQUEST ="CREATE_LIKE_REQUEST"
export const CREATE_LIKE_SUCCESS ="CREATE_LIKE_SUCCESS"
export const CREATE_LIKE_FAIL = "CREATE_LILE_FAIL"

export const GET_ANALYTICS_REQUEST ="GET_ANALYTICS_REQUEST"
export const GET_ANALYTICS_SUCCESS ="GET_ANALYTICS_SUCCESS"
export const GET_ANALYTICS_FAIL ="GET_ANALYTICS_FAIL"

export const GET_LIKE_ANALYTICS_REQUEST ="GET_LIKE_ANALYTICS_REQUEST"
export const GET_LIKE_ANALYTICS_SUCCESS ="GET_LIKE_ANALYTICS_SUCCESS"
export const GET_LIKE_ANALYTICS_FAIL ="GET_LIKE_ANALYTICS_FAIL"

export const LIKE_NUMBER_REQUEST = "LIKE_NUMBER_REQUEST"
export const LIKE_NUMBER_SUCCESS = "LIKE_NUMBER_SUCCESS"
export const LIKE_NUMBER_FAIL = "LIKE_NUMBER_FAIL"

export const VIEWS_NUMBER_REQUEST = "VIEWS_NUMBER_REQUEST"
export const VIEWS_NUMBER_SUCCESS = "VIEWS_NUMBER_SUCCESS"
export const VIEWS_NUMBER_FAIL = "VIEWS_NUMBER_FAIL"

export const POST_NUMBER_REQUEST = "POST_NUMBER_REQUEST"
export const POST_NUMBER_SUCCESS = "POST_NUMBER_SUCCESS"
export const POST_NUMBER_FAIL = "POST_NUMBER_FAIL"

export const PLOT_NUMBER_REQUEST = "PLOT_NUMBER_REQUEST"
export const PLOT_NUMBER_SUCCESS = "PLOT_NUMBER_SUCCESS"
export const PLOT_NUMBER_FAIL = "PLOT_NUMBER_FAIL"

export const RENT_NUMBER_REQUEST = "RENT_NUMBER_REQUEST"
export const RENT_NUMBER_SUCCESS = "RENT_NUMBER_SUCCESS"
export const RENT_NUMBER_FAIL = "RENT_NUMBER_FAIL"

export const SALE_NUMBER_REQUEST = "SALE_NUMBER_REQUEST"
export const SALE_NUMBER_SUCCESS = "SALE_NUMBER_SUCCESS"
export const SALE_NUMBER_FAIL = "SALE_NUMBER_FAIL"

export const HOUSE_NUMBER_REQUEST = "HOUSE_NUMBER_REQUEST"
export const HOUSE_NUMBER_SUCCESS = "HOUSE_NUMBER_SUCCESS"
export const HOUSE_NUMBER_FAIL = "HOUSE_NUMBER_FAIL"

export const SET_UP_SOCKET = 'SET_UP_SOCKET';
export const ALL_MESSAGES = 'ALL_MESSAGES';
export const ALL_CHATS = "ALL_CHATS"

export const GET_USER_REQUEST= "GET_USER_REQUEST"
export const GET_USER_SUCCESS= "GET_USER_SUCCESS"
export const GET_USER_FAIL= "GET_USER_FAIL"

export const ALL_MESSAGES_REQUEST= "ALL_MESSAGES_REQUEST"
export const ALL_MESSAGES_SUCCESS= "ALL_MESSAGES_SUCCESS"
export const ALL_MESSAGES_FAIL= "GALL_MESSAGESFAIL"

export const  ALL_CHATS_REQUEST = "ALL_CHATS_REQUEST"
export const  ALL_CHATS_SUCCESS ="ALL_CHATS_SUCCESS"
export const  ALL_CHATS_FAIL ="ALL_CHATS_FAIL"

export const USER_REQUEST= "USER_REQUEST"
export const USER_SUCCESS= "USER_SUCCESS"
export const USER_FAIL= "USER_FAIL"

export const GET_PROFILE_REQUEST="GET_PROFILE_REQUEST"
export const GET_PROFILE_SUCCESS="GET_PROFILE_SUCCESS"
export const GET_PROFILE_FAIL="GET_PRFILE_FAIL"

export const ALL_USER_REQUEST="ALL_USER_REQUEST"
export const ALL_USER_SUCCESS="ALL_USER_SUCCESS"
export const ALL_USER_FAIL="ALL_USER_FAIL"

export const FORGOT_PASSWORD_REQUEST="FORGOT_PASSWORD_REQUEST"
export const FORGOT_PASSWORD_SUCCESS="FORGOT_PASSWORD_SUCCESS"
export const FORGOT_PASSWORD_FAIL="FORGOT_PASSWORD_FAIL"

export const RESET_PASSWORD_REQUEST="RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS="RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL="RESET_PASSWORD_FAIL"

export const GET_USSD_REQUEST = "GET_USSD_REQUEST"
export const GET_USSD_SUCCESS = "GET_USSD_SUCCESS"
export const GET_USSD_FAIL = "GET_USSD_FAIL"

export const GET_ONEUSSD_REQUEST = "GET_ONEUSSD_REQUEST"
export const GET_ONEUSSD_SUCCESS = "GET_ONEUSSD_SUCCESS"
export const GET_ONEUSSD_FAIL = "GET_ONEUSSD_FAIL"

export const USSD_RENT_CATEGORY = "USSD_RENT_CATEGORY";

export const USSD_SELL_CATEGORY = "USSD_SELL_CATEGORY";


// export const USSD_FOR_RENT_REQUEST = "USSD_FOR_RENT_REQUEST";
// export const USSD_FOR_RENT_SUCCESS = "USSD_FOR_RENT_SUCCESS";
// export const USSD_FOR_RENT_FAIL = "USSD_FOR_RENT_FAIL";

// export const USSD_FOR_SALE_REQUEST = "USSD_FOR_SALE_REQUEST";
// export const USSD_FOR_SALE_SUCCESS = "USSD_FOR_SALE_SUCCESS";
// export const USSD_FOR_SALE_FAIL = "USSD_FOR_SALE_FAIL";

export const FILTER_OFFER_REQUEST = "FILTER_OFFER_REQUEST";
export const FILTER_OFFER_SUCCESS = "FILTER_OFFER_SUCCESS";
export const FILTER_OFFER_FAIL = "FILTER_OFFER_FAIL";

export const FILTER_OFFER_SALE_REQUEST = "FILTER_OFFER_SALE_REQUEST";
export const FILTER_OFFER_SALE_SUCCESS = "FILTER_OFFER_SALE_SUCCESS";
export const FILTER_OFFER_SALE_FAIL = "FILTER_OFFER_SALE_FAIL";

export const FILTER_REQUEST_REQUEST = "FILTER_REQUEST_REQUEST";
export const FILTER_REQUEST_SUCCESS = "FILTER_REQUEST_SUCCESS";
export const FILTER_REQUEST_FAIL = "FILTER_REQUEST_FAIL";

export const FILTER_REQUEST_SALE_REQUEST = "FILTER_REQUEST_SALE_REQUEST";
export const FILTER_REQUEST_SALE_SUCCESS = "FILTER_REQUEST_SALE_SUCCESS";
export const FILTER_REQUEST_SALE_FAIL = "FILTER_REQUEST_SALE_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const UPDATE_ROLE_REQUEST = "UPDATE_ROLE_REQUEST"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL"

export const UPDATE_USER_ROLE  = "UPDATE_USER_ROLE "

export const GET_APP_MESSAGE_REQUEST = "GET_APP_MESSAGE_REQUEST"
export const GET_APP_MESSAGE_SUCCESS = "GET_APP_MESSAGE_SUCCESS"
export const GET_APP_MESSAGE_FAIL = "GET_APP_MESSAGE_FAIL"

export const GET_DETAILS_MESSAGE_REQUEST = "GET_DETAILS_MESSAGE_REQUEST"
export const GET_DETAILS_MESSAGE_SUCCESS = "GET_DETAILS_MESSAGE_SUCCESS"
export const GET_DETAILS_MESSAGE_FAIL = "GET_DETAILS_MESSAGE_FAIL"

export const GET_YEAR_VIEWS_REQUEST = "GET_YEAR_VIEWS_REQUEST"
export const GET_YEAR_VIEWS_SUCCESS = "GET_YEAR_VIEWS_SUCCESS"
export const GET_YEAR_VIEWS_FAIL = "GET_YEAR_VIEWS_FAIL"

export const GET_YEAR_LIKES_REQUEST = "GET_YEAR_LIKES_REQUEST"
export const GET_YEAR_LIKES_SUCCESS = "GET_YEAR_LIKES_SUCCESS"
export const GET_YEAR_LIKES_FAIL = "GET_YEAR_LIKES_FAIL"

export const TOTAL_POST_REQUEST = "TOTAL_POST_REQUEST"
export const TOTAL_POST_SUCCESS = "TOTAL_POST_SUCCESS"
export const TOTAL_POST_FAIL = "TOTAL_POST_FAIL"

export const TOTAL_VIEWS_REQUEST = "TOTAL_VIEWS_REQUEST"
export const TOTAL_VIEWS_SUCCESS = "TOTAL_VIEWS_SUCCESS"
export const TOTAL_VIEWS_FAIL = "TOTAL_VIEWS_FAIL"

export const VISITORS_TRACK_REQUEST = "TOTAL_VISITORS_REQUEST"
export const VISITORS_TRACK_SUCCESS = "TOTAL_VISITORS_SUCCESS"
export const VISITORS_TRACK_FAIL = "TOTAL_VISITORS_FAIL"

export const VISITOR_ANALYTIC_REQUEST = "VISITOR_ANALYTIC_REQUEST"
export const VISITOR_ANALYTIC_SUCCESS = "VISITOR_ANALYTIC_SUCCESS"
export const VISITOR_ANALYTIC_FAIL = "VISITOR_ANALYTIC_FAIL"

export const GET_TOTAL_VIEW_ANALYTIC_REQUEST = "GET_TOTAL_VIEW_ANALYTIC_REQUEST"
export const GET_TOTAL_VIEW_ANALYTIC_SUCCESS = "GET_TOTAL_VIEW_ANALYTIC_SUCCESS"
export const GET_TOTAL_VIEW_ANALYTIC_FAIL = "GET_TOTAL_VIEW_ANALYTIC_FAIL"

export const GET_TOTAL_LIKE_ANALYTIC_REQUEST = "GET_TOTAL_LIKE_ANALYTIC_REQUEST"
export const GET_TOTAL_LIKE_ANALYTIC_SUCCESS = "GET_TOTAL_LIKE_ANALYTIC_SUCCESS"
export const GET_TOTAL_LIKE_ANALYTIC_FAIL = "GET_TOTAL_LIKE_ANALYTIC_FAIL"

export const CASHIN_REQUEST = "CASHIN_REQUEST"
export const CASHIN_SUCCESS = "CASHIN_SUCCESS"
export const CASHIN_FAIL = "CASHIN_FAIL"

export const CASHOUT_REQUEST = "CASHOUT_REQUEST"
export const CASHOUT_SUCCESS = "CASHOUT_SUCCESS"
export const CASHOUT_FAIL = "CASHOUT_FAIL"

export const PUBLISH_PROPERTY_REQUEST = "PUBLISH_PROPERTY_REQUEST";
export const PUBLISH_PROPERTY_SUCCESS = "PUBLISH_PROPERTY_SUCCESS";
export const PUBLISH_PROPERTY_FAIL = "PUBLISH_PROPERTY_FAIL";

export const CHECK_PAYMENT_STATUS_REQUEST = "CHECK_PAYMENT_STATUS_REQUEST";
export const CHECK_PAYMENT_STATUS_SUCCESS = "CHECK_PAYMENT_STATUS_SUCCESS";
export const CHECK_PAYMENT_STATUS_FAIL = "CHECK_PAYMENT_STATUS_FAIL";

export const REVENUE_REQUEST = "REVENUE_REQUEST";
export const REVENUE_SUCCESS = "REVENUE_SUCCESS";
export const REVENUE_FAIL = "REVENUE_FAIL";

export const GET_USER_PAYMENT_REQUEST = "GET_USER_PAYMENT_REQUEST";
export const GET_USER_PAYMENT_SUCCESS = "GET_USER_PAYMENT_SUCCESS";
export const GET_USER_PAYMENT_FAIL = "GET_USER_PAYMENT_FAIL";

export const EXPENSE_REQUEST = "EXPENSE_REQUEST";
export const EXPENSE_SUCCESS = "EXPENSE_SUCCESS";
export const EXPENSE_FAIL = "EXPENSE_FAIL";

export const GET_USER_REVENUE_SUCCESS = "GET_USER_REVENUE_SUCCESS";
export const GET_USER_REVENUE_REQUEST = "GET_USER_REVENUE_REQUEST";
export const GET_USER_REVENUE_FAIL = "GET_USER_REVENUE_FAIL";

export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";

export const GET_USER_EXPENSES_REQUEST = "GET_USER_EXPENSES_REQUEST";
export const GET_USER_EXPENSES_SUCCESS = "GET_USER_EXPENSES_SUCCESS";
export const GET_USER_EXPENSES_FAIL = "GET_USER_EXPENSES_FAIL";

export const ADD_SENT_MESSAGE = 'ADD_SENT_MESSAGE'

export const MY_PROPERTIES_REQUEST = "MY_PROPERTIES_REQUEST";
export const MY_PROPERTIES_SUCCESS = "MY_PROPERTIES_SUCCESS";
export const MY_PROPERTIES_FAIL = "MY_PROPERTIES_FAIL";

export const BLOCK_PROPERTY_REQUEST = "BLOCK_PROPERTY_REQUEST";
export const BLOCK_PROPERTY_SUCCESS = "BLOCK_PROPERTY_SUCCESS";
export const BLOCK_PROPERTY_FAIL = "BLOCK_PROPERTY_FAIL";
