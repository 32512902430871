export const plotType = [
  'Residential plot',
  'Commercial plot',
  'Industrial plot',
  'Other',
]

export const propertyType = [
  "Ghetto",
  "Office",
  "Apartments",
  "Warehouse",
  "Residentials",
  "Commercial"
]

export const property = [
  "residential house",
  "apartment",
  "commercial house",
  "warehouse",
  "offices"
]
